<template>

  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
      :blankUserData="blankUserData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>每页</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条记录</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="登录账号..." />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
                v-if="$can('update', 'accout')"
              >
                <span class="text-nowrap">添加管理员</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <!-- v-if="$can('read', 'accout')" -->
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="暂无任何数据"
      >

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <span
              v-for="(ite,index) in data.item.role"
              :key="index"
              class="align-text-top text-capitalize">
                {{ ite.name }}
            </span>
          </div>
        </template>

        <!-- Column: createdAt .slice(0,10) -->
        <template #cell(createdAt)="data">
            {{ data.item.createdAt.slice(0,10) }}
        </template>

        <!-- Column: Status @change="statusEvent(row.id)" -->
        <template #cell(status)="data">
          <b-form-checkbox
            v-if="$can('status', 'accout')"
            @change="statusEvent(data.item.id)"
            :checked="data.item.status == 1 ? true:false"
            class="custom-control-primary" name="status" switch
          />
          <span v-else>无权限</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            @click="editEvent(data.item)"
            variant="flat-primary" size="sm"
          >
            编辑
          </b-button>

          <b-button
            @click="editProjects(data.item)"
            variant="flat-primary" size="sm"
          >
            项目
          </b-button>

          <b-button
            @click="delEvent(data.item.id)"
            variant="flat-primary" size="sm"
          >
            删除
          </b-button>

          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editEvent(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="delEvent(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
          </b-dropdown> -->
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">当前显示 {{ dataMeta.from }} 到 {{ dataMeta.to }} 共 {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <edit-projects v-model="projectModalShall" :projects="projects" :id="userId" @set-projects="setProject" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { rolesSelectApi, adminStatusApi, delAdminApi, adminuserInfoApi, updateProjectsApi } from '@/api/system/authority'
import { ref, onMounted } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import editProjects from './editProjects.vue'
import useUsersList from './useUsersList'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    UserListAddNew,
    editProjects,
    vSelect,
  },
  setup(props, context) {
    const { $swal, $can } = context.root
    // Use toast
    const toast = useToast()

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = ref([])
    const getRoleOption = () => {
      rolesSelectApi()
        .then(res => {
          roleOptions.value = res.data
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取角色列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }
    onMounted(() => {
      if ($can('select', 'accout')) {
        getRoleOption()
      } else {
        toast({
          component: ToastificationContent,
          props: { title: '您没有获取角色下拉项的权限', icon: 'AlertTriangleIcon', variant: 'danger' },
        })
      }
    })

    const statusOptions = [
      { label: '启用', value: true },
      { label: '禁用', value: false },
    ]

    const projectModalShall = ref(false)
    const projects = ref([])
    const userId = ref(0)
    const editProjects = item => {
      adminuserInfoApi({ id: item.id })
        .then(res => {
          projects.value = res.data.projects
          userId.value = res.data.id
          projectModalShall.value = true
        })
    }
    const setProject = (id, projects) => {
      updateProjectsApi({
        id,
        project_ids: projects
      })
        .then(() => {
          refetchData()
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '修改用户关联项目失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      refUserListTable,
      refetchData,

      blankUserData,

    } = useUsersList()

    const editEvent = item => {
      item.role_ids = []
      item.role.map(it => item.role_ids.push(it.id))
      item.realname = item.fullName
      item.status = item.status
      blankUserData.value = JSON.parse(JSON.stringify(item))

      isAddNewUserSidebarActive.value = true
    }

    const statusEvent = id => {
      adminStatusApi({ id })
        .then(() => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: { title: '管理员状态更新成功', icon: 'CheckCircleIcon', variant: 'success' },
          })
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '管理员状态更新失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }
    const delEvent = id => {
      $swal({
        title: '确定删除此管理员吗?',
        text: '删除管理员可能会导致数据不完整,请知悉!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          delAdminApi({ id })
            .then(() => {
              refetchData()
              toast({
                component: ToastificationContent,
                props: { title: '删除管理员成功', icon: 'CheckCircleIcon', variant: 'success' },
              })
            })
            .catch(err => {
              toast({
                component: ToastificationContent,
                props: { title: '删除管理员失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
              })
            })
        }
      })
    }

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      refUserListTable,
      refetchData,

      blankUserData,

      roleOptions,
      statusOptions,

      editEvent,
      statusEvent,
      delEvent,

      projectModalShall,
      projects,
      setProject,
      editProjects,
      userId,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
