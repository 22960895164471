<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="isAddNewUserSidebarActive = false"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ userData.id > 0 ? '编辑管理员' : '添加管理员' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="isAddNewUserSidebarActive = false"
        >

          <!-- username -->
          <validation-provider #default="validationContext" name="管理员账号" rules="required">
            <b-form-group label="管理员账号" label-for="username">
              <b-form-input
                id="username"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
                placeholder="例如: myusername"
              />

              <b-form-invalid-feedback> {{ validationContext.errors[0] }} </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- password -->
          <validation-provider #default="validationContext" name="账号密码" rules="required" vid="password"
          >
            <b-form-group label="账号密码" label-for="password">
              <b-form-input
                id="password"
                v-model="userData.password"
                :state="getValidationState(validationContext)"
                trim
                placeholder="例如: Ac!@1234"
              />
              <b-form-invalid-feedback> {{ validationContext.errors[0] }} </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!--  Password Confirm -->
          <validation-provider #default="validationContext" name="重复密码" rules="required|confirmed:password">
            <b-form-group label="重复密码" label-for="passwordCon">
              <b-form-input
                id="passwordCon"
                v-model="userData.passwordCon"
                :state="getValidationState(validationContext)"
                trim
                placeholder="例如: Ac!@1234"
              />
              <b-form-invalid-feedback> {{ validationContext.errors[0] }} </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- realname -->
          <validation-provider #default="validationContext" name="管理员姓名" rules="required">
            <b-form-group label="管理员姓名" label-for="realname">
              <b-form-input
                id="realname"
                v-model="userData.realname"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="例如: 张三"
              />
              <b-form-invalid-feedback> {{ validationContext.errors[0] }} </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- phone -->
          <validation-provider #default="validationContext" name="管理员电话" rules="required|digits:11">
            <b-form-group label="管理员电话" label-for="phone">
              <b-form-input
                id="phone"
                v-model="userData.phone"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="例如: 131 0000 0000"
              />
              <b-form-invalid-feedback> {{ validationContext.errors[0] }} </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- role_ids -->
          <validation-provider #default="validationContext" name="角色身份" rules="required" v-if="$can('select', 'accout')">
            <b-form-group label="角色身份" label-for="role_ids" :state="getValidationState(validationContext)" v-if="$can('select', 'accout')">
              <v-select
                v-model="userData.role_ids"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                label="name"
                multiple
                :reduce="val => val.id"
                :clearable="false"
                input-id="role_ids"
              >
                <template v-slot:no-options>
                  对不起,没有找到相关信息
                </template>
              </v-select>
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- status  -->
          <validation-provider #default="validationContext" name="status" rules="required">
            <b-form-group label="账号状态" label-for="status">
              <b-form-checkbox v-model="userData.status" :value='true' :unchecked-value='false' name="status" switch inline />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ userData.id > 0 ? '编辑' : '添加' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ref, watch, toRefs } from '@vue/composition-api'

import { required, confirmed, integer, digits } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

// 接口调用
import { adminUpdateApi } from '@/api/system/authority'

export default {
  components: {
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    blankUserData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      confirmed,
    }
  },
  setup(propsValue, { emit }) {
    const props = toRefs(propsValue)
    // Use toast
    const toast = useToast()

    const userData = ref(JSON.parse(JSON.stringify(props.blankUserData.value)))

    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(props.blankUserData.value))
    }
    const clearFormData = () => { userData.value = { status: true, id: 0, role_ids: [] } }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetuserData, clearFormData)

    watch(props.blankUserData, () => { resetForm() })
    watch(props.isAddNewUserSidebarActive, val => { if (!val) { clearForm() } })

    const onSubmit = () => {
      adminUpdateApi(userData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: { title: '更新管理员成功', icon: 'CheckCircleIcon', variant: 'success' },
          })
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '更新管理员失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,

      required,
      confirmed,
      integer,
      digits,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
