import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// 接口调用
import { adminUserListApi } from '@/api/system/authority'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const blankUserData = ref({
    id: 0,
    username: '',
    realname: '',
    password: '',
    phone: '',
    role_ids: [],
    status: 1,
  })

  const refUserListTable = ref(null)

  // Table Handlers projectNames
  const tableColumns = [
    { key: 'fullName', label: '管理员姓名', sortable: true },
    { key: 'username', label: '登录账号', sortable: true },
    { key: 'role', label: '角色身份', sortable: true },
    { key: 'phone', label: '手机号码', formatter: title, sortable: true },
    { key: 'projectNames', label: '关联项目', sortable: true },
    { key: 'createdAt', label: '创建时间', sortable: true },
    { key: 'status', label: '账号状态', sortable: true },
    { key: 'actions', label: '操作' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => { refetchData() })

  const fetchUsers = (ctx, callback) => {
    adminUserListApi({ page: currentPage.value, size: perPage.value, username: searchQuery.value })
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalUsers.value = total
      })
      .catch(err => {
        toast({
          component: ToastificationContent,
          props: { title: '获取管理员列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = status => {
    if (status === 2) return 'warning'
    if (status === 1) return 'success'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,

    isSortDirDesc,
    refUserListTable,

    blankUserData,

    resolveUserStatusVariant,
    refetchData,
  }
}
