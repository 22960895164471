<template>
  <b-modal
    id="accout-project-modal" :visible="shallShowModal" centered
    footer-class="d-flex justify-content-between"
    body-class="px-2" size="md" no-close-on-backdrop static
    @change="(val) => $emit('update:shall-show-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title"> 关联项目 </h5>
      <div class="modal-actions">
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="$emit('update:shall-show-modal', false)" />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div>
        <b-button variant="outline-secondary" class="cursor-pointer" @click="$emit('update:shall-show-modal', false)">
          取消
        </b-button>
      </div>

      <div>
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" class="mb-1 mb-sm-0" @click="updateForm">
          确定
        </b-button>
      </div>
    </template>

    <validation-observer ref="refFormObserver">
      <b-form>
        <b-row>
          <!-- projects -->
          <b-col cols="12">
            <validation-provider name="关联项目" #default="{ errors }" rules="required">
              <b-form-group label="关联项目" label-for="projects" :state="errors.length > 0 ? false:null">
                <b-form-checkbox-group
                  id="projects" name="projects" class="demo-inline-spacing" value-field="id" text-field="name"
                  v-model="formData.project" :options="projects"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { distinctArrToPropArr } from '@/libs/array'

export default {
  directives: {
    Ripple,
  },
  components: {
    formValidation,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  model: {
    prop: 'shallShowModal',
    event: 'update:shall-show-modal',
  },
  props: {
    shallShowModal: {
      type: Boolean,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* 必要组件加载区域 */

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */

    // 表单验证dom对象
    const refFormObserver = ref(null)
    // 表单数据项
    const formData = ref({ project: [] })
    // 表单数据清空函数
    const resetFormData = () => {
      formData.value = { project: [] }
      refFormObserver.value.reset()
    }

    /* 接口调用区域 */

    // 保存 处理编辑
    const updateForm = () => {
      refFormObserver.value.validate().then(success => {
        if (success) {
          emit('set-projects', props.id, formData.value.project)
          emit('update:shall-show-modal', false)
        }
      })
    }

    /* 组件内部逻辑处理，生命周期问题处理区域 */

    // 编辑窗口关闭，清空表单并刷新验证
    watch(() => props.shallShowModal, val => {
      !val ? resetFormData() : formData.value.project = distinctArrToPropArr(props.projects.filter(item => item.checked === true), 'id')
    })

    return {
      formData,
      refFormObserver,
      resetFormData,
      updateForm,

      required,
    }
  },
}
</script>
